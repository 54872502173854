import * as React from 'react'
import { Helmet } from 'react-helmet'
import LayoutService from '../../templates/LayoutService'
import { blogHeader, blogPostContainer, postContentBlock } from '../../styles/components/blog.module.scss'
import { pageContentMedia } from '../../styles/components/blocks.module.scss'
import { StaticImage } from 'gatsby-plugin-image'
import { imgBox } from '../../styles/components/boxes.module.scss'
import '../../styles/components/content.module.scss'
import ServicesList from '../../components/blocks/ServicesList'
import { Link } from 'gatsby'

const BlogPage = () => {
  return (
    <LayoutService>
      <Helmet>
        <title>What is the Best Day of the Week for a Boat Party? | Tampa Bay Boating Adventures</title>
      </Helmet>
      <main className='container'>
        <div className='row'>
          <div className='col-xxs-12'>
            <div className={blogHeader}>
              <div className={imgBox}>
                <StaticImage
                  alt="Sunset over Hillsborough Bay in Florida."
                  src="../../images/blog/sunset-over-hillsborough-bay-in-florida.jpg"
                />
              </div>
            </div>
            <div className={blogPostContainer}>
              <div className='row center'>
                <div className='col'>
                  <h1>What is the Best Day of the Week for a Boat Party?</h1>
                  <p>November 16, 2022</p>
                </div>
              </div>
              <div className='row'>
                <div className='col-xxs-12'>
                  <div className={postContentBlock}>
                    <p>There is truly nothing better than heading down to Tampa, stepping aboard a boat and sailing across the water with the sun beating down on you. A boat is a great place to throw a party – think food, drinks, socializing and dipping in the water to cool off when you anchor. Around the area of Tampa, there are many islands that you can visit when you charter a boat, meaning there are endless opportunities for parties and gatherings on warm sand beaches too. But when is the best day to party? Here is what we have learned through our years of experience.</p>
                    <div className={pageContentMedia}>
                      <div className={imgBox}>
                        <StaticImage
                          alt="Charter boat party at Party Island near Tampa Bay, Florida."
                          src="../../images/blog/charter-boat-party-at-party-island-near-tampa-bay-florida.jpg"
                        />
                      </div>
                    </div>
                    <h2>If you want a party, then the weekends are the answer.</h2>
                    <p>Like they say, Saturdays are for the boys boats. If you are looking for the busiest, most passionate party environment, then you need to be out on the water on the weekend. The weekends are the most popular for boat parties as this is when most people have their time off, as they are working Monday to Friday. The more people at the boat party, the more buzzing and exciting it will feel.  The popularity of boat parties at the weekend can easily be seen near Beer Can Island, as boats make their way from Downtown Tampa to the party island. All day and in the evening during the weekend you will see hundreds of boats heading up the river and out into Tampa Bay for parties and to spend some time out on the water.</p>
                    <hr />
                    <h2>Weekend parties take on another life at Beer Can Island and other nearby islands in Tampa.</h2>
                    <p>When chartering a boat for a day, as well as partying onboard, you could stop at the many islands in the bay and join others on the beach. There are many islands in the area known for their beach parties that are only accessible by boat such as Beer Can Island or the amazing sandbar parties at Weedon Island. In the sunny Florida weather, these white sand beaches are the perfect location for sunbathing, partying and meeting other people who just want to have fun.</p>
                    <hr />
                    <h2>Looking for a more low-key boat experience? Try a week day charter.</h2>
                    <p>If you can’t make it to a weekend party, there are also some great boat parties during the week. Boat parties in Tampa during the week are great, and still take advantage of the great weather and location but they do tend to be slightly quieter and have a more relaxed feel to them. If you are looking for an exclusive feel, then during the week may be the best option as quite often you can find little islands, such as Fantasy Island are unoccupied- meaning you can have your own private island party.</p>
                    <p>Chartering a boat during the week also means that fewer boats are on the water, meaning your boat can get in a better position to visit a particular island or to watch the stunning sunset over the horizon. Particularly in the summer months or over the holidays, the area can get very busy with tourists and visitors to the area, which can make the boat party feel less private as other boats may be able to look at you due to their close distance.</p>
                    <p>In summary, Tampa is a great location no matter which day of the week you are looking to party. For busier atmospheres choose a weekend date and for more laidback or quieter occasions, consider a weekday.</p>
                    <hr />
                    <h2>Select Your Party Boat Charter</h2>
                    <h3>See what all's included with our wide variety of party boat charter packages.</h3>
                  </div>
                </div>
              </div>
            </div>
            <ServicesList />
          </div>
        </div>
      </main>
    </LayoutService>
  )
}

export default BlogPage